<template>
  <div>
    <s-search
      :queryItems="queryItems"
      @fetchData="fetchUsers"
      ref="SSearch"
      v-if="SSearchShow"
    ></s-search>
    <div class="table-button table-button-flex">
      <div>
        <!-- type="primary" -->

        <a-popconfirm
          title="确定取消订单?"
          ok-text="确定"
          cancel-text="取消"
          @confirm="cancelOrder(record)"
          @cancel="cancel"
        >
          <a-button
            v-if="
              isShowBtns.indexOf(
                'AlreadyOrder-/api/constructionMaterial/addOrder'
              ) > -1
            "
            >取消订单</a-button
          >
        </a-popconfirm>
      </div>
      <div>
        <a-button type="plain" icon="redo" @click="$refs.table.refresh(true)"
          >刷新</a-button
        >
        <columns-set
          tableName="AlreadyOrder"
          :columns="columns"
          @changeColumns="changeColumns"
        ></columns-set>
      </div>
    </div>
    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :columns="tableColumns"
      :data="loadData"
      :showPagination="true"
      :scroll="{ x: '1300px', y: 'calc(82vh - 150px )' }"
      tableLayout="fixed"
      :row-selection="rowSelection"
    >
      <!-- :rowSelection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }" -->
      <!--  @change="changeTable" -->
      <!-- <span slot="wsBcId" slot-scope="text">
        <div>{{ CompaniesResult(text) }}</div>
      </span> -->

      <!-- 收货地址 -->
      <span slot="receivingAddress" slot-scope="text">
        <a-tooltip>
          <template slot="title">
            {{ text }}
          </template>
          {{ text }}
        </a-tooltip>
      </span>

      <!-- 供应商 -->
      <span slot="supplierName" slot-scope="text">
        <a-tooltip>
          <template slot="title">
            {{ text }}
          </template>
          {{ text }}
        </a-tooltip>
      </span>
      <!--接单情况-->
      <span slot="acceptOrderState" slot-scope="text">
        <div :class="'acceptOrderstate' + text">
          {{ text | ReceiveOrdersState }}
        </div>
      </span>
      <!-- state 发货情况 -->
      <span slot="state" slot-scope="text">
        <div :class="SendGoodsState[text] ? 'Goodsstate' + text : 'Goodsstate'">
          {{ SendGoodsState[text] ? SendGoodsState[text] : text }}
        </div>
      </span>
      <!-- 操作 -->
      <span slot="action" slot-scope="text, record" class="action">
        <a @click="GoToOrderDetails(record, '订单详情')">详情</a>
        <a-popconfirm
          title="确定取消订单?"
          ok-text="确定"
          cancel-text="取消"
          @confirm="cancelOrder(record)"
          @cancel="cancel"
        >
          <a
            class="editBtn"
            v-if="
              isShowBtns.indexOf(
                'AlreadyOrder-/api/constructionMaterial/addOrder'
              ) > -1
            "
            >取消</a
          >
          <!-- @click="cancelOrder(record)" -->
        </a-popconfirm>
      </span>
    </s-table>
  </div>
</template>

<script>
import { STable, SSearch, ColumnsSet } from "@/components";
import { SelectOptions } from "@/config/SelectOptions.js";
import { getWorkSitelist } from "@/api/apiJF/schedule";
import { GetAlreadyOrderList, DeleteOrder } from "@/api/apiJF/material";
import { GetCompanies } from "@/api/device";
import moment from "moment";
// 搜索条件
const queryItems = [
  {
    label: "订单号",
    value: "orderNo",
    type: "input",
    defaultValue: "",
  },
  {
    label: "接单情况",
    value: "acceptOrderState",
    type: "select",
    selectOption: "AcceptOrderState",
    defaultValue: "",
  },

  {
    label: "收货地址",
    value: "receivingAddress",
    type: "input",
    defaultValue: "",
  },
  {
    label: "收货人",
    value: "receivingName",
    type: "input",
    defaultValue: "",
  },
  {
    label: "分公司",
    value: "companyIds",
    type: "select",
    selectOption: "CompanyListUnAll",
    defaultValue: "",
  },

  {
    label: "下单时间",
    value: "startTime",
    type: "dateRange",
    range: ["startTime", "endTime"],
    // keyType: "WorkerAttend",
    // defaultValue: [],
  },
];
const columns = [
  {
    title: "订单号",
    width: "200px",
    dataIndex: "orderNo",
    ellipsis: true,
    align: "center",
  },
  {
    title: "收货地址",
    // width: "150px",
    dataIndex: "receivingAddress",
    scopedSlots: { customRender: "receivingAddress" },
  },
  {
    title: "收货人",
    width: "90px",
    dataIndex: "receivingName",
    align: "center",
    ellipsis: true,
  },
  {
    title: "主要货物",
    width: "100px",
    dataIndex: "mainGoods",
    align: "center",
  },

  {
    title: "下单时间",
    width: "200px",
    dataIndex: "createTime",
    align: "center",
  },
  {
    title: "供应商",
    width: "200px",
    dataIndex: "supplierName",
    // align: "center",
    scopedSlots: { customRender: "supplierName" },
    ellipsis: true,
  },
  {
    title: "接单情况",
    width: "100px",
    dataIndex: "acceptOrderState",
    align: "center",
    scopedSlots: { customRender: "acceptOrderState" },
    ellipsis: true,
  },
  {
    title: "发货情况",
    width: "100px",
    dataIndex: "state",
    align: "center",
    scopedSlots: { customRender: "state" },
    ellipsis: true,
  },
  {
    title: "分公司",
    width: "100px",
    dataIndex: "companyName",
    align: "center",
  },
  {
    title: "操作",
    width: "90px",
    scopedSlots: { customRender: "action" },
    fixed: "right",
    dataIndex: "action",
    align: "center",
  },
];

export default {
  name: "AlreadyOrder",
  components: {
    STable,
    SSearch,
    ColumnsSet,
  },
  data() {
    this.queryItems = queryItems;
    this.columns = columns;
    return {
      tableColumns: [],
      SSearchShow: true,
      DrawerTitle: "",
      // 查询参数
      queryParam: {
        cmMState: "2", //已下单
        orderNo: "",
        acceptOrderState: "",
        receivingAddress: "",
        receivingName: "",
        startTime: "",
        endTime: "",
        companyIds: "",
        pageNo: 1,
        pageSize: 10,
        receivingAddress: "",
      },
      isShowBtns: [],
      record: "",
      CompanyList: {},
      type: "",
      filters: {},
      CompanyListid: [],
      pageNo: 1,
      pageSize: 10,
      selectedRowKeys: [],
      selectedRows: [],
      stateList: [],
      SendGoodsState: [
        "待处理",
        "已取消",
        "已发货",
        "已送达",
        "已验货",
        "已退货",
      ],
    };
  },
  created() {
    this.GetCompaniesId();
    this.tableColumns = columns;
    this.changeColumns();
    this.filters = SelectOptions;
    let params = this.$route.params;
    if (params.receivingAddress) {
      this.queryParam.receivingAddress = params.receivingAddress;
      this.queryItems.forEach((el) => {
        if (el.value == "receivingAddress") {
          el.defaultValue = params.receivingAddress;
        }
      });
    }
  },
  mounted() {
    this.isShowBtns = sessionStorage.getItem("isShowBtnsJF").split(",");
  },
  computed: {
    rowSelection() {
      const { selectedRowKeys } = this;
      return {
        selectedRowKeys, //必须return一下，不然选中项不能清空
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(
            `selectedRowKeys: ${selectedRowKeys}`,
            "selectedRows: ",
            selectedRows
          );
          this.selectedRowKeys = selectedRowKeys;
          this.selectedRows = selectedRows;
        },
        getCheckboxProps: (record) => ({
          props: {
            disabled: record.state != "0", // state：订单状态（0待处理、1已取消、2已完成、3部分退货、4全部退货、5已退货）
            // name: record.name,
          },
        }),
      };
    },
  },
  methods: {
    // 获取分公司id
    GetCompaniesId() {
      GetCompanies()
        .then((res) => {
          // console.log(res);
          res.data.forEach((el) => {
            this.CompanyListid.push(el.bc_id);
            this.CompanyList[el.bc_id] = el.bc_name;
          });
        })
        .then(() => {
          if (this.queryParam.companyIds == "") {
            this.queryParam.companyIds = this.CompanyListid.toString();
            this.$refs.table.refresh(true);
          }

          //当包含的分公司小于1个的时候，查询条件不显示分公司查询 表格不显示分公司名称
          if (this.CompanyListid.length < 1) {
            let data = [...this.queryItems];
            this.queryItems = data.filter((item) => item.label != "公司名称");
            if (this.queryItems.length == 0) {
              this.SSearchShow = false;
            }
            let data1 = [...this.columns];
            this.tableColumns = data1.filter(
              (item) => item.title != "公司名称"
            );
            this.columns = data1.filter((item) => item.title != "公司名称");
          }
        });
    },
    CompaniesResult(a) {
      return this.CompanyList[a];
    },
    // 自定义表头列
    changeColumns() {
      let ColumnsSet = JSON.parse(
        localStorage.getItem("AlreadyOrderColumnsSet")
      );
      let value = ColumnsSet ? ColumnsSet : [];
      let selectColumns = columns.filter((el) => value.includes(el.dataIndex));
      this.tableColumns = selectColumns.length ? selectColumns : columns;
    },
    // 加载数据方法 必须为 Promise 对象
    loadData(parameter) {
      if (parameter) {
        this.queryParam.pageNo = parameter.pageNo;
        this.queryParam.pageSize = parameter.pageSize;
      } else {
      }
      if (this.queryParam.companyIds != "") {
        let newObject = {};
        for (let el in this.queryParam) {
          if (this.queryParam[el] != "" && this.queryParam[el]) {
            newObject[el] = this.queryParam[el];
          }
        }
        let orderParam = Object.keys(newObject);
        const param = Object.assign({}, parameter, this.queryParam);
        const requestParameters = this.$order(param, orderParam);
        const params = {
          ...requestParameters,
          verify: this.$verify(requestParameters, orderParam),
        };
        return GetAlreadyOrderList(params).then((res) => {
          // console.log("物料清单", res);
          return res.data;
        });
      }
    },

    fetchUsers(value) {
      // this.queryParam = value;
      for (let el in this.queryParam) {
        this.queryParam[el] = value[el];
      }
      // console.log("this.queryParam", this.queryParam, value);
      if (
        this.queryParam.companyIds == "" ||
        this.queryParam.companyIds == undefined
      ) {
        this.queryParam.companyIds = this.CompanyListid.toString();
      }
      this.$refs.table.refresh(true);
    },
    // 跳往订单详情
    GoToOrderDetails(record, name) {
      console.log(name, record);
      this.$router.push({
        path: "/OrderDetails",
        query: { record: JSON.stringify(record) },
      });
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      console.log(selectedRowKeys, selectedRows);
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    // 取消下单
    cancelOrder(record) {
      console.log("取消下单", record);
      let params = {
        ids: record ? record.id : this.selectedRowKeys.toString(),
      };
      DeleteOrder(params).then((res) => {
        console.log("res", res);
        if (res.code == 200) {
          this.$message.success("取消订单成功");
          this.$refs.table.refresh(true);
          this.selectedRowKeys = [];
          this.selectedRows = [];
        } else {
          this.$message.error("请至少选择一个订单取消");
        }
      });
    },
    cancel(e) {
      console.log(e);
      this.$message.error("取消退单");
    },
  },
};
</script>
<style lang="less" scoped>
.table-button-flex {
  display: flex;
  justify-content: space-between;
}
.editBtn {
  margin-left: 10px;
}
.Goodsstate {
  color: #20b01d;
  background: rgba(84, 195, 82, 0.2);
  border-radius: 4px;
  padding: 5px;
}
.Goodsstate0 {
  color: #ffba07;
  background: rgba(255, 202, 64, 0.2);
  border-radius: 4px;
  padding: 5px;
}
.Goodsstate1 {
  color: #20b01d;
  background: rgba(84, 195, 82, 0.2);
  border-radius: 4px;
  padding: 5px;
}
.Goodsstate2 {
  color: #2164d8;
  background: rgba(33, 100, 216, 0.2);
  border-radius: 4px;
  padding: 5px;
}
.acceptOrderstate0 {
  color: #ffba07;
}
.acceptOrderstate1 {
  color: #999999;
}
.acceptOrderstate2 {
  color: #ff0000;
}
</style>