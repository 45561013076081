var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.SSearchShow)?_c('s-search',{ref:"SSearch",attrs:{"queryItems":_vm.queryItems},on:{"fetchData":_vm.fetchUsers}}):_vm._e(),_c('div',{staticClass:"table-button table-button-flex"},[_c('div',[_c('a-popconfirm',{attrs:{"title":"确定取消订单?","ok-text":"确定","cancel-text":"取消"},on:{"confirm":function($event){return _vm.cancelOrder(_vm.record)},"cancel":_vm.cancel}},[(
            _vm.isShowBtns.indexOf(
              'AlreadyOrder-/api/constructionMaterial/addOrder'
            ) > -1
          )?_c('a-button',[_vm._v("取消订单")]):_vm._e()],1)],1),_c('div',[_c('a-button',{attrs:{"type":"plain","icon":"redo"},on:{"click":function($event){return _vm.$refs.table.refresh(true)}}},[_vm._v("刷新")]),_c('columns-set',{attrs:{"tableName":"AlreadyOrder","columns":_vm.columns},on:{"changeColumns":_vm.changeColumns}})],1)]),_c('s-table',{ref:"table",attrs:{"size":"small","rowKey":"id","columns":_vm.tableColumns,"data":_vm.loadData,"showPagination":true,"scroll":{ x: '1300px', y: 'calc(82vh - 150px )' },"tableLayout":"fixed","row-selection":_vm.rowSelection},scopedSlots:_vm._u([{key:"receivingAddress",fn:function(text){return _c('span',{},[_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v(" "+_vm._s(text)+" ")]),_vm._v(" "+_vm._s(text)+" ")],2)],1)}},{key:"supplierName",fn:function(text){return _c('span',{},[_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v(" "+_vm._s(text)+" ")]),_vm._v(" "+_vm._s(text)+" ")],2)],1)}},{key:"acceptOrderState",fn:function(text){return _c('span',{},[_c('div',{class:'acceptOrderstate' + text},[_vm._v(" "+_vm._s(_vm._f("ReceiveOrdersState")(text))+" ")])])}},{key:"state",fn:function(text){return _c('span',{},[_c('div',{class:_vm.SendGoodsState[text] ? 'Goodsstate' + text : 'Goodsstate'},[_vm._v(" "+_vm._s(_vm.SendGoodsState[text] ? _vm.SendGoodsState[text] : text)+" ")])])}},{key:"action",fn:function(text, record){return _c('span',{staticClass:"action"},[_c('a',{on:{"click":function($event){return _vm.GoToOrderDetails(record, '订单详情')}}},[_vm._v("详情")]),_c('a-popconfirm',{attrs:{"title":"确定取消订单?","ok-text":"确定","cancel-text":"取消"},on:{"confirm":function($event){return _vm.cancelOrder(record)},"cancel":_vm.cancel}},[(
            _vm.isShowBtns.indexOf(
              'AlreadyOrder-/api/constructionMaterial/addOrder'
            ) > -1
          )?_c('a',{staticClass:"editBtn"},[_vm._v("取消")]):_vm._e()])],1)}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }